export const onInitialClientRender = () => {
  document.addEventListener(
    "click",
    (event) => {
      // Find the closest anchor tag
      const anchor = event.target.closest("a");

      if (!anchor) {
        // If the clicked element is not a link, do nothing
        return;
      }

      const { target, href } = anchor;

      if (target === "_blank") {
        // Prevent default behavior and stop propagation
        event.preventDefault();
        event.stopImmediatePropagation(); // Ensure no other listener interferes
        window.open(href, "_blank", "noopener,noreferrer");
        return;
      }

      if (!href.startsWith(window.location.origin)) {
        console.log("External link, default behavior allowed:", href);
        return;
      }

      console.log("Intercepted internal link navigation:", href);
      event.preventDefault();
      event.stopImmediatePropagation(); // Ensure no other listener interferes

      // Use Gatsby's navigation for internal links
      window.___navigate(href);
    },
    true, // Use capture phase to handle the event before bubbling listeners
  );
};
